import {IonContent, IonPage} from '@ionic/react';
import React from "react";
import {changeUrl} from "../../services/Navigation";
import Header from "../../components/header/Header";
import {BreadcrumbInterface} from "../../components/header/interfaces";
import Api from "../../services/Api";
import {connect, ConnectedProps} from "react-redux";
import {sortTable} from "../../helpers";
import HelpModal from "../../components/help/HelpModal";
import {formatMeetingStatusNew, getMeetingsBehind} from "./helpers";
import {ClientInterface, MEETING_STATUS} from "./interfaces";

interface InvitationInterface {
  email: string
  firstName: string
  lastName: string
  id: number
  invitedAt: string
  invitations: number
  licenseName: string
}

interface LicenseCount {
  name: string
  count: number
}

interface MeasureCount {
  measures: number
  clients: number
}

const mapState = (state: any) => ({
  navigation: state.navigation
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>


interface ComponentState {
  loaded: boolean
  clients: Array<ClientInterface>
  access_clients: Array<ClientInterface>
  optimize_clients: Array<ClientInterface>
  maximize_clients: Array<ClientInterface>
  clientsNotOnboarded: Array<ClientInterface>
  invitations: Array<InvitationInterface>
  upgradeInvitations: Array<InvitationInterface>
  accessToAllDepartments: boolean
  seatsCoach: number
  seatsPlatform: number
  seeStatusMeetings: boolean
  seeCoachRequests: boolean
  meetingsDone: number
  oldMeasures: number
  meetingsUpcoming: number
  licenseList: Array<LicenseCount>
  access_licenselist: Array<LicenseCount>,
  optimize_licenselist: Array<LicenseCount>,
  maximize_licenselist: Array<LicenseCount>,
  measureList: Array<MeasureCount>
  measures: number
  focusAreas: number
  focusTasks: number
}



class Clients extends React.Component<PropsFromRedux, ComponentState>  {

  api = Api.getInstance()
  sortOrders: Array<boolean> = [true, false, false, false, false, false, false, false]

  state = {
    loaded: false,
    clients: [] as Array<ClientInterface>,
    access_clients: [] as Array<ClientInterface>,
    optimize_clients: [] as Array<ClientInterface>,
    maximize_clients: [] as Array<ClientInterface>,
    clientsNotOnboarded: [] as Array<ClientInterface>,
    invitations: [] as Array<InvitationInterface>,
    upgradeInvitations: [] as Array<InvitationInterface>,
    accessToAllDepartments: false,
    seatsCoach: 0,
    seatsPlatform: 0,
    seeStatusMeetings: false,
    seeCoachRequests: false,
    meetingsDone: 0,
    oldMeasures: 0,
    meetingsUpcoming: 0,
    licenseList: [],
    access_licenselist: [],
    optimize_licenselist: [],
    maximize_licenselist: [],
    measureList: [],
    measures: 0,
    focusAreas: 0,
    focusTasks: 0
  }

  componentDidMount() {
   this.getData()
  }

  getData = () => {
    this.setState({loaded: false})
    this.api.get('v1/clients/' + this.api.profile.customer?.id).then(response => {
      console.log("--- clients ---", response.json.clients)
      const access_clients: ClientInterface[] = response.json.clients.filter((el: ClientInterface) => el.licenseName == "Access")
      const optimize_clients = response.json.clients.filter((el: ClientInterface)  => el.licenseName == "Optimise")
      const maximize_clients = response.json.clients.filter((el: ClientInterface)  => el.licenseName == "Maximize")
      const access_licenselist: LicenseCount[] = response.json.licenseList.filter((el: LicenseCount) => el.name == "Access")
      const optimize_licenselist: LicenseCount[] = response.json.licenseList.filter((el: LicenseCount) => el.name == "Optimize")
      const maximize_licenselist: LicenseCount[] = response.json.licenseList.filter((el: LicenseCount) => el.name == "Maximize")
      console.log("--- access_clients ---", access_clients)
      console.log("--- optimize_clients ---", optimize_clients)
      console.log("--- maximize_clients ---", maximize_clients)
      console.log('response.json.licenseList', response.json.licenseList)
      console.log("--- access_licenselist ---", access_licenselist)
      console.log("--- optimize_licenselist ---", optimize_licenselist)
      console.log("--- maximize_licenselist ---", maximize_licenselist)
      this.setState({
        loaded: true,
        clients: response.json.clients,
        access_clients: access_clients,
        optimize_clients: optimize_clients,
        maximize_clients: maximize_clients,
        clientsNotOnboarded: response.json.clientsNotOnboarded,
        invitations: response.json.invitations,
        upgradeInvitations: response.json.upgradeInvitations,
        seatsCoach: response.json.seatsCoach,
        seatsPlatform: response.json.seatsPlatform,
        meetingsDone: response.json.meetingsDone,
        meetingsUpcoming: response.json.meetingsUpcoming,
        licenseList: response.json.licenseList,
        access_licenselist: access_licenselist,
        optimize_licenselist: optimize_licenselist,
        maximize_licenselist: maximize_licenselist,
        measureList: response.json.measureList,
        measures: response.json.measures,
        focusAreas: response.json.focusAreas,
        focusTasks: response.json.focusTasks,
        oldMeasures: response.json.oldMeasures
      })
    })
  }

  sortCol = (col: number, tableId: string) => {
    this.sortOrders[col] = !this.sortOrders[col]
    sortTable(tableId, col, this.sortOrders[col])
  }

  renderTableHead = (tableId: string, headers: Array<string>) => {
    return (
      <>
        <colgroup>
          {
            headers.map((header, i) => {
              let colStyle = i % 2 === 1 ? 'col_bg' : ''
              return (
                <col key={"col_" + tableId + "_" + i} className={colStyle} />
              )
            })
          }
        </colgroup>
        <thead>
        <tr>
          {
            headers.map((name: string, i) => {
              return (
                <th key={"th_" + tableId + "_" + i} onClick={() => this.sortCol(i, tableId)}
                    className="cursor-pointer underline">{name}</th>
              )
            })
          }
        </tr>
        </thead>
      </>
    )
  }

  showStatusMeetings = () => {
    const clientsBehind: Array<ClientInterface> = []
    const clientsCatchup: Array<ClientInterface> = []

    for (const client of this.state.clients) {
      if (client.hasCoach) {
        const meetingStatus = getMeetingsBehind(client.licenseStart, client.licenseEnd, client.meetings_total,
          client.meetings_consumed, client.meetings_booked_period)
        if (meetingStatus === MEETING_STATUS.BEHIND) {
          clientsBehind.push(client)
        } else if (meetingStatus === MEETING_STATUS.CATCHUP) {
          clientsCatchup.push(client)
        }
      }
    }

    if (clientsBehind.length === 0 && clientsCatchup.length === 0) {
      return (
        <div className="mt-4 flex items-center">
          <img src="/assets/icon/ok_circle.svg" alt="status" style={{width: "16px", height: "16px"}}/>
          <p className="ml-2">All clients are perfectly on track!</p>
        </div>
      )
    }
    return (
      <>
        {
          clientsBehind.length > 0 &&
            <div className="mt-4 flex items-center">
                <img src="/assets/icon/warning_triangle.svg" alt="status" style={{width: "16px", height: "16px"}}/>
                <p className="ml-2"><span className="bold">Behind</span> {clientsBehind.length} clients</p>
            </div>
        }
        {
          clientsCatchup.length > 0 &&
            <div className="mt-4 flex items-center">
                <img src="/assets/icon/exclamation_circle.svg" alt="status" style={{width: "16px", height: "16px"}}/>
                <p className="ml-2"><span className="bold">Catch up</span> {clientsCatchup.length} clients</p>
            </div>
        }
        {
          this.state.seeStatusMeetings &&
          <>
            {
              clientsBehind.length > 0 &&
              <div className="mt-4">
                <h4>Behind</h4>
                {
                  clientsBehind.map(client => {
                    return (
                      <p key={"status_meeting_" + client.id} className="cursor-pointer underline"
                         onClick={() => changeUrl('/user/' + client.id)}>
                        {client.firstName} {client.lastName}
                      </p>
                    )
                  })
                }
              </div>
            }
            {
              clientsCatchup.length > 0 &&
                <div className="mt-4">
                    <h4>Catch up</h4>
                  {
                    clientsCatchup.map(client => {
                      return (
                        <p key={"status_meeting_" + client.id} className="cursor-pointer underline"
                           onClick={() => changeUrl('/user/' + client.id)}>
                          {client.firstName} {client.lastName}
                        </p>
                      )
                    })
                  }
                </div>
            }

          </>
        }
        <p className="mt-10 underline cursor-pointer"
           onClick={() => this.setState({seeStatusMeetings: !this.state.seeStatusMeetings})}>
          {this.state.seeStatusMeetings ? 'Close' : 'See users'}
        </p>
      </>
    )
  }

  showCoachRequests = () => {
    const coachRequests: Array<ClientInterface> = []
    for (const client of this.state.clients) {
      if (!client.hasCoach && client.coachRequestStatus?.createdAt) {
        coachRequests.push(client)
      }
    }
    if (coachRequests.length === 0) {
      return (<></>)
    }
    return (
      <div className='ml-8 box_gray font_small heatmap_info_width'>
        <h3>Coach requests</h3>
        <p className="mt-4">
          {coachRequests.length} users have requested coach!
        </p>

        {
          this.state.seeCoachRequests &&
          <div className="mt-4">
            {
              coachRequests.map(client => {
                return (
                  <p key={"coach_requests_" + client.id} className="cursor-pointer underline"
                     onClick={() => changeUrl('/user/' + client.id)}>
                    {client.firstName} {client.lastName}  {client.coachRequestStatus.createdAt.substring(0,10)}
                  </p>
                )
              })
            }
          </div>
        }

        <p className="mt-10 underline cursor-pointer"
           onClick={() => this.setState({seeCoachRequests: !this.state.seeCoachRequests})}>
          {this.state.seeCoachRequests ? 'Close' : 'See users'}
        </p>
      </div>
    )
  }

  render () {
    const breadcrumbs: Array<BreadcrumbInterface> = [{
      name: 'Overview',
      link: '/'
    }]
    if (!this.state.loaded) {
      return (
        <IonPage>
          <IonContent>
            <Header name="Users" breadcrumbs={breadcrumbs} />
            <div className="page_content">
              <div className="page_section">Loading...</div>
            </div>
          </IonContent>
        </IonPage>
      )
    }

    return (
      <IonPage>
        <IonContent>
          <Header name="Users" breadcrumbs={breadcrumbs} reload={this.getData}/>
          <div className="page_content">
            <div className="page_section">
              <h1>User status</h1>
              <div className="page_title_bar" />
              <h2 className="mt-8">Top priorities</h2>
              <div className="flex mt-4">

                <div className='box_gray font_small heatmap_info_width'>
                  <h3>Status meetings</h3>
                  {
                    this.showStatusMeetings()
                  }
                </div>
                {
                  this.showCoachRequests()
                }
              </div>

              <h2 className="mt-24">Other metrics</h2>

              <div className="flex mt-4">
                <div className='box_gray font_small'>
                  <h3>Meeting status</h3>
                  <p className="mt-4">{this.state.meetingsDone} meetings consumed</p>
                  <p>{this.state.meetingsUpcoming} upcoming meetings</p>
                </div>

                <div className='ml-8 box_gray font_small'>
                  <h3>License</h3>
                  <div className="my-4">

                    <p className=" bold">{this.state.clients.filter(client => client.hasActiveLicense).length} active licenses</p>
                    <p>of which:</p>
                  </div>
                  {
                    this.state.optimize_licenselist.map((licenseCount: LicenseCount, i) => {
                      return (
                        <div key={"optimize_license_count_" + i}>{licenseCount.count} {licenseCount.name} (has coach)</div>
                      )
                    })
                  }
                  {
                    this.state.maximize_licenselist.map((licenseCount: LicenseCount, i) => {
                      return (
                        <div key={"maximise_license_count_" + i}>{licenseCount.count} {licenseCount.name} (has coach)</div>
                      )
                    })
                  }
                  {
                      (this.state.clientsNotOnboarded.length > 0 || this.state.invitations.length > 0) &&
                      <div>
                        <div className="mt-4 bold">
                          {
                          this.state.clients.filter(client => (!client.hasCoach && client.hasActiveLicense)).length +
                          this.state.clientsNotOnboarded.length +
                          this.state.invitations.length
                          } Access, of which:
                        </div>
                        <div>{this.state.clients.filter(client => (!client.hasCoach && client.hasActiveLicense)).length} active</div>
                        <div>{this.state.clientsNotOnboarded.length} onboarding</div>
                        <div>{this.state.invitations.length} invited but not onboarded</div>
                      </div>
                  }
                  {
                      (this.state.clientsNotOnboarded.length === 0 && this.state.invitations.length === 0) &&
                      <div className="">{
                        this.state.clients.filter(client => (!client.hasCoach && client.hasActiveLicense)).length
                      } Access</div>
                  }
                </div>

                <div className='ml-8 box_gray font_small'>
                  <h3>Measurements</h3>
                  <p className="mt-4">Total measurements: {this.state.measures}</p>
                  {/*{*/}
                  {/*  this.state.oldMeasures > 0 &&*/}
                  {/*    <p className="mt-4">Measurements from inactive users: {this.state.oldMeasures}</p>*/}
                  {/*}*/}
                  <p>Average per user: {(this.state.measures / this.state.clients.length).toFixed(1)}</p>
                  <h4 className="mt-4">Measurements per user:</h4>
                  {
                    this.state.measureList.map((measureCount: MeasureCount, i) => {
                      return (
                        <div key={"measure_count_" + i}>{measureCount.measures} measures = {measureCount.clients}&nbsp;
                          users</div>
                      )
                    })
                  }
                </div>

                <div className='ml-8 box_gray font_small'>
                  <h3>Development areas</h3>
                  <p className="mt-4">Total active Development areas: {this.state.focusAreas}</p>
                  <p>Average per user: {(this.state.focusAreas / this.state.clients.length).toFixed(1)}</p>

                  <p className="mt-4">Total ongoing/completed questions: {this.state.focusTasks}</p>
                  <p>Average per user: {(this.state.focusTasks / this.state.clients.length).toFixed(1)}</p>
                </div>
              </div>
            </div>

            <div className="page_section mt-4">
              <h2 className="flex items-center">
                {this.state.clients.filter(client => client.hasCoach).length} users with coach
                {
                  this.state.seatsCoach > 0 &&
                  <>
                      &nbsp;(max {this.state.seatsCoach})
                  </>
                }

                <HelpModal name="users_with_coach" />
              </h2>
              {
                this.state.clients.filter(client => client.hasCoach).length > 0 &&
                <>
                  <div style={{ maxWidth: '605px' }} className="page_title_bar" />

                  <table className="data_table lines mt-8" id="t">
                    {
                      this.renderTableHead("t",
                        ["Name", "Onboarded", "License", "Start", "End", "Status meetings", "Meetings consumed", "Upcoming Meetings", "Meetings included"])
                    }
                    <tbody>
                    {
                      this.state.clients.filter(client => client.hasCoach).map((client, index) => {
                        return (
                          <tr key={"area_question_" + index}>
                            <td data-private className="underline cursor-pointer" onClick={() => changeUrl('/user/' + client.id)}>
                              {client.firstName} {client.lastName}
                            </td>
                            <td>{client.createdAt.substring(0, 10)}</td>
                            <td>{client.licenseName}</td>
                            <td>{client.licenseStart.substring(0, 10)}</td>
                            <td>{client.licenseEnd ? client.licenseEnd.substring(0, 10) : "No end"}</td>
                            <td className="underline cursor-pointer" onClick={() => changeUrl('/user/' + client.id)}>
                              {formatMeetingStatusNew(client.licenseStart, client.licenseEnd, client.meetings_total,
                                client.meetings_consumed, client.meetings_booked_period)}
                            </td>
                            <td>
                              {client.meetings_consumed}
                            </td>
                            <td>
                              {client.meetings_booked_period}
                            </td>
                            <td>
                              {client.meetings_total}
                            </td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </table>
                </>
              }
              <div className="mt-16 box_gray" style={{maxWidth: "478px"}}>
                <h3>Meeting status explanation</h3>
                <div className="mt-4 flex">
                  <img alt="warning" src="/assets/icon/warning_triangle.svg" style={{width: "16px", height: "16px", marginTop: "3px"}}/>
                  <div className="ml-2">
                    <h4>Behind</h4>
                    <p>3 or more meetings behind*</p>
                  </div>
                </div>
                <div className="mt-4 flex">
                  <img alt="!" src="/assets/icon/exclamation_circle.svg" style={{width: "16px", height: "16px", marginTop: "3px"}}/>
                  <div className="ml-2">
                    <h4>Catch up</h4>
                    <p>2 meetings behind*</p>
                  </div>
                </div>
                <div className="mt-4 flex">
                  <img alt="ok" src="/assets/icon/ok_circle.svg" style={{width: "16px", height: "16px", marginTop: "3px"}}/>
                  <div className="ml-2">
                    <h4>On track</h4>
                    <p>1 or 0 meetings behind*</p>
                  </div>
                </div>

                <div className="top_margin font_small">
                  *Behind recommended meeting frequency. Recommended meeting frequency depends on start and end date
                  for the coaching license, but most common is 2 meetings/month.
                </div>
              </div>
            </div>

            <div className="page_section mt-4">
              <h2 className="flex items-center">
                {this.state.clients.filter(client => (!client.hasCoach && client.hasActiveLicense)).length}
                &nbsp;users with platform access
                {
                  this.state.seatsPlatform > 0 &&
                  <>
                      &nbsp;(max {this.state.seatsPlatform})
                  </>
                }
                <HelpModal name="users_with_platform" />
              </h2>

              {
                this.state.clients.filter(client => (!client.hasCoach && client.hasActiveLicense)).length > 0 &&
                  <>
                    <div style={{ maxWidth: '605px' }} className="page_title_bar" />
                    <table className="data_table lines mt-8" id="no_coach">
                      {
                        this.renderTableHead("no_coach",
                          ["Name", "Onboarded", "License", "Start", "End",
                            "Requested coach", "Invited to coach"])
                      }
                        <tbody>
                        {
                          this.state.clients.filter(client => (!client.hasCoach && client.hasActiveLicense))
                            .map((client, index) => {
                            return (
                              <tr key={"area_question_" + index}>
                                <td data-private className="underline cursor-pointer" onClick={() => changeUrl('/user/' + client.id)}>
                                  {client.firstName} {client.lastName}
                                </td>
                                <td>{client.createdAt.substring(0, 10)}</td>
                                <td>{client.licenseName}</td>
                                <td>{client.licenseStart.substring(0, 10)}</td>
                                <td>{client.licenseEnd ? client.licenseEnd.substring(0, 10) : "No end"}</td>
                                <td>
                                  {
                                    client.coachRequestStatus?.createdAt ?
                                      client.coachRequestStatus.createdAt.substring(0,10)
                                      : '-'
                                  }
                                </td>
                                <td>
                                  {client.invitedToCoach.length > 10 ? client.invitedToCoach.substring(0,10) : '-'}
                                </td>
                              </tr>
                            )
                          })
                        }
                        </tbody>
                    </table>
                  </>
              }
            </div>

            <div className="page_section mt-4">
              <h2 className="flex items-center">
                {this.state.clients.filter(client => !client.hasActiveLicense).length} users without license
                <HelpModal name="users_without_license" />
              </h2>

              {
                this.state.clients.filter(client => !client.hasActiveLicense).length > 0 &&
                  <>
                      <div style={{ maxWidth: '605px' }} className="page_title_bar" />
                      <table className="data_table lines mt-8" id="platform">
                        {
                          this.renderTableHead("platform",
                            ["Name", "Onboarded", "Previous license", "Start", "End"])
                        }
                          <tbody>
                          {
                            this.state.clients.filter(client => !client.hasActiveLicense).map((client, index) => {
                              return (
                                <tr key={"area_question_" + index}>
                                  <td data-private className="underline cursor-pointer" onClick={() => changeUrl('/user/' + client.id)}>
                                    {client.firstName} {client.lastName}
                                  </td>
                                  <td>{client.createdAt.substring(0, 10)}</td>
                                  <td>{client.licenseName}</td>
                                  <td>{client.licenseStart.substring(0, 10)}</td>
                                  <td>{client.licenseEnd ? client.licenseEnd.substring(0, 10) : "No end"}</td>
                                </tr>
                              )
                            })
                          }
                          </tbody>
                      </table>
                  </>
              }
            </div>

            <div className="page_section mt-4">
              <h2 className="flex items-center">
                {this.state.clientsNotOnboarded.length} users onboarding
                <HelpModal name="users_onboarding" />
              </h2>
              {
                this.state.clientsNotOnboarded.length > 0 &&
                <>
                    <div style={{ maxWidth: '605px' }} className="page_title_bar" />

                    <table className="data_table lines mt-8" id="n">
                      {
                        this.renderTableHead("n", ["Name", "Created", "License", "Start", "End"])
                      }
                      <tbody>
                      {
                        this.state.clientsNotOnboarded.map((client, index) => {
                          return (
                            <tr key={"area_question_" + index}>
                              <td data-private>{client.firstName} {client.lastName}</td>
                              <td>{client.createdAt.substring(0, 10)}</td>
                              <td>{client.licenseName}</td>
                              <td>{client.licenseStart.substring(0, 10)}</td>
                              <td>{client.licenseEnd ? client.licenseEnd.substring(0, 10) : "No end"}</td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </table>
                </>
              }
            </div>

            {
              this.state.invitations &&
                <div className="page_section mt-4">
                  <h2 className="flex items-center">
                    {this.state.invitations.length} users invited but not started
                    <HelpModal name="users_invited_not_started" />
                  </h2>
                  {
                    this.state.invitations.length > 0 &&
                    <>
                        <div style={{ maxWidth: '605px' }} className="page_title_bar" />

                        <table className="data_table lines mt-8" id="i">
                          {
                            this.renderTableHead("i", ["Name", "Email", "Invited", "Invitations sent", "License"])
                          }
                          <tbody>
                          {
                            this.state.invitations.map((invitation, index) => {
                              return (
                                <tr key={"area_question_" + index}>
                                  <td data-private>{invitation.firstName} {invitation.lastName}</td>
                                  <td data-private>{invitation.email}</td>
                                  <td>{invitation.invitedAt ? invitation.invitedAt.substring(0, 10) : '-'}</td>
                                  <td>{invitation.invitations}</td>
                                  <td>{invitation.licenseName}</td>
                                </tr>
                              )
                            })
                          }
                          </tbody>
                        </table>
                    </>
                  }
                </div>
            }

            {
              this.state.upgradeInvitations &&
                <div className="page_section mt-4">
                    <h2 className="flex items-center">
                      {this.state.upgradeInvitations.length} users invited to upgrade license but not upgraded
                        <HelpModal name="users_upgrade_invited_not_started" />
                    </h2>
                  {
                    this.state.upgradeInvitations.length > 0 &&
                      <>
                          <div style={{ maxWidth: '605px' }} className="page_title_bar" />

                          <table className="data_table lines mt-8" id="i">
                            {
                              this.renderTableHead("i", ["Name", "Email", "Invited", "Invitations sent", "License",])
                            }
                              <tbody>
                              {
                                this.state.upgradeInvitations.map((invitation, index) => {
                                  return (
                                    <tr key={"area_question_" + index}>
                                      <td data-private>{invitation.firstName} {invitation.lastName}</td>
                                      <td data-private>{invitation.email}</td>
                                      <td>{invitation.invitedAt ? invitation.invitedAt.substring(0, 10) : '-'}</td>
                                      <td>{invitation.invitations}</td>
                                      <td>{invitation.licenseName}</td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </table>
                      </>
                  }
                </div>
            }
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default connector(Clients)
